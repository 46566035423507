// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$font-family-sans-serif: 'Helvetica', 'sans-serif';
$icon-font-path: "/fonts/bootstrap/";
$fa-font-path:   "/fonts/font-awesome/";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome.scss";

[v-cloak] {
  display: none;
}